import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import styled from 'styled-components';
import { isAccelPayMessage } from '../lib/utils';

const Root = styled.div`
  display: flex;
  gap: 4px;
`;

export const ShippingBadge = styled.span`
  background: black;
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 12px;
  margin: 4px 4px 4px 0;
`;

export const DeliveryBadge = styled(ShippingBadge)`
  background: transparent;
  border: 1px solid black;
  color: black;
`;

const FulfillmentBadges = ({ variantId }: { variantId: string }) => {
  const [shippingAvailable, setShippingAvailable] = useState(false);
  const [deliveryAvailable, setDeliveryAvailable] = useState(false);

  useEffect(() => {
    const handleMessage = e => {
      if (!isAccelPayMessage(e)) {
        return;
      }

      const { action, value } = e.data;
      if (action === 'bc-gopuff-variant-data') {
        console.log('bc-gopuff-variant-data', value);
        const inventoryStatus = value[variantId]?.inventoryStatus;
        if (['available', 'preorder'].includes(inventoryStatus)) {
          setDeliveryAvailable(true);
        } else {
          setDeliveryAvailable(false);
        }
      } else if (action === 'bc-variant-data') {
        const inventoryStatus = value[variantId]?.inventoryStatus;
        if (['available', 'preorder'].includes(inventoryStatus)) {
          setShippingAvailable(true);
        } else {
          setShippingAvailable(false);
        }
      }
    };

    window.addEventListener('message', handleMessage, false);
    window.apbrand?.listeners?.push(handleMessage);
  }, []);

  return (
    <Root className="accelpay-fulfillment-badges">
      {shippingAvailable && (
        <ShippingBadge className="accelpay-shipping-badge">
          Standard Shipping
        </ShippingBadge>
      )}
      {deliveryAvailable && (
        <DeliveryBadge className="accelpay-delivery-badge">
          30-Minute Delivery
        </DeliveryBadge>
      )}
    </Root>
  );
};

export default FulfillmentBadges;

import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import styled, { css } from 'styled-components';
import DoorDashIcon from './icons/DoorDashIcon';
import { isAccelPayMessage } from '../lib/utils';

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  margin: 8px 0;
  background-color: #fa2c33;
  border-radius: 24px;
  max-width: 400px;
  padding: 4px;

  ${props => css`
    ${props.disabled ? 'pointer-events:none;background:gray;' : ''},
  `}
`;

interface ButtonProps {
  variantId?: string;
}

const DoorDashButton = ({ variantId }: ButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [variantMap, setVariantMap] = useState({});
  const [ddSic, setDdSic] = useState();
  const [unavailable, setUnavailable] = useState(false);

  useEffect(() => {
    const handleMessage = e => {
      if (!isAccelPayMessage(e)) {
        return;
      }

      const { action, value } = e.data;
      if (action === 'bc-dd-variant-data') {
        setVariantMap(value);
      }
    };

    window.addEventListener('message', handleMessage, false);
    window.apbrand?.listeners?.push(handleMessage);
  }, []);

  useEffect(() => {
    if (Object.keys(variantMap).length) {
      setLoading(false);
    }

    if (variantId) {
      const variant = variantMap[variantId];
      if (variant) {
        setDdSic(variant.externalTags.dd_sic);
        setUnavailable(variant.inventoryStatus === 'unavailable');
      }
    }
  }, [variantMap, variantId]);

  return (
    <Link
      target="_blank"
      rel="noopener"
      href={
        ddSic
          ? `https://www.doordash.com/products/-/${ddSic}?utm_campaign=CX_US_PA_LY_LY_NVT_CUSXXX__ALCOHO_%20Accelpay_Feed_%7B%7Bbrand&utm_medium=AccelPay&utm_source=AccelPay`
          : ''
      }
      disabled={!ddSic || loading || unavailable}
    >
      <DoorDashIcon />
    </Link>
  );
};

export default DoorDashButton;
